import React from "react";
import Footer from '../components/footer'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../css/aboutcomp.css";
import "../css/about.css"
import NavBar from '../components/navbar'
export default class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div>
        <div>
          <NavBar />
        </div>
        <div className="about-banner py-20 w-full vh-half banner-color flex">
                    <div className="container mx-auto relative self-center mt-64">
                      <h1 className="about-banner-title text-7xl md:text-7xl text-white uppercase font-bold text-center mb-12">ABOUT</h1>
                        <div className="about-info-strip rounded-xl bg-yellow-400">
                          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-4 gap-0 p-2.5">
                <div className="col-span-1 m-2 rounded-xl lg:p-4 p-0 bg-yellow-400 rounding">
                  
                  <div className="time relative">
                    <h3 className="open-time text-sm uppercase font-bold text-center">Working Hours</h3>
                    <p className="text-sm md:text-base text-center">Mon - Fri</p>
                    <p className="text-sm md:text-base text-center">9:00am to 5:00pm</p>
                  </div>
                </div>
                <div className="col-span-1 m-2 rounded-xl lg:p-4 p-0 bg-yellow-400 rounding">
                 
                  <div className="address relative">
                    <h3 className="text-center text-sm uppercase font-bold ">Address</h3>

                    <p className="text-sm text-center md:text-base text-black">Fitlane Sports,<br />2nd Floor, Pillaiyar Kovil Street,<br />Sir Padmavathi Nagar, Mathiravedu, <br />Thiruverkadu, Chennai - 99.</p>
                  </div>
                </div>
                <div className="col-span-1 m-2 rounded-xl lg:p-4 p-0 bg-yellow-400 rounding">
                  
                  <div className="phone relative">
                    <h3 className="text-sm text-center uppercase font-bold ">Contact</h3>
                    <p className="text-sm text-center md:text-base"> business@fitlanesports.com</p>
                    <p className="text-sm text-center md:text-base">+91 7448-32-33-34</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="about-info-setion w-full bg-gray-200">
            <div className="grid grid-cols-1 lg:grid-cols-2 relative py-20">
              <div className="content-left p-14 mt-12 col-span-1">
                <div className="z-20 relative lg:pt-0 pt-8">
                  <h3 className="text-3xl font-bold uppercase text-yellow-400 md:text-3xl mb-2 uppercase">Our AIM</h3>
                  <h2 className="text-4xl font-bold uppercase font-color md:text-4xl mb-2 uppercase">Mission</h2>
                  <p className="text-sm md:text-base">The objective of this program is to provide opportunities and experiences to all students that enable them to achieve total wellness, leading to a longer and helthier life.</p>
                  <h2 className="text-4xl font-bold uppercase font-color md:text-4xl mb-2 uppercase mt-8">Vision</h2>
                  <p className="text-sm md:text-base">To make the next generation fit by introducing them to true sports.</p>
                </div>
              </div>

              <div className="col-span-1 lg:p-14 px-8 lg:px-8 image-right">
                <img src="../group.jpg"  class="image-right z-20 relative rounded-xl" />
              </div>
              <div className="bg-yellow-400 absolute position h-full w-1/4 z-10"></div>
              <div className="col-span-1 lg:p-14 lg:py-14 pt-14 lg:pt-0  px-8 lg:px-8 image-right bg-gray-200">
                <img src="../para.jpg"  class="image-right z-20 relative rounded-xl" />
              </div>
              <div className="content-left lg:p-8 lg:pt-2 px-8 pt-12 bg-gray-200">
                <div className="z-20 relative">
                  <h3 className="text-3xl font-bold uppercase md:text-3xl mb-2 text-yellow-400 uppercase">Our Responsibility</h3>
                  <h2 className="text-4xl font-bold uppercase md:text-4xl mb-2 font-color uppercase">what we do</h2>
                  <p className="text-sm md:text-base">Fitlane Sports is the best Physical Education Company in India. It teaches the children complete physical activity and sports. Fitlane Sports creates fit and healthy children through sports and games. It provides curriculum, sports equipment, and qualified resources.</p>
                  <p className="text-sm mt-4 md:text-base">The Fitlane Sports conducted fitness assessments and reports of the student performance. Through this assessment, the school team was formed. There are not enough opportunities for children, but Fitlane Sports fills the void by providing a structured program.</p>
                  <div class="mt-4">
                    <p class="text-xl font-bold">The program is designed based on:</p>
                    <ul>
                      <li class="mt-2">
                        <img class="inline-block" src="../check.png" width="20px" height="20px" />  Inclusive</li>

                      <li class="mt-2">
                        <img class="inline-block" src="../check.png" width="20px" height="20px" />   Age - Appropriate Learning</li>

                      <li class="mt-2">
                        <img class="inline-block" src="../check.png" width="20px" height="20px" />   Progress Tracking Assessments</li>

                      <li class="mt-2">
                        <img class="inline-block" src="../check.png" width="20px" height="20px" />   Certified Trainers</li>

                      <li class="mt-2">
                        <img class="inline-block" src="../check.png" width="20px" height="20px" />   Skill Development & Fitness</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="product-showcase-strip py-20 bg-yellow-400">
            <div className="product-list grid grid-cols-1 lg:grid-cols-4 container mx-auto">
              <div className="showcase-layer col-span-1 pb-12 lg:pb-0 flex">
                <div className="self-center">
                  <h3 className="showcase-title text-3xl font-extrabold text-yellow-400 banner-color text-center uppercase">Our Other Services</h3>
                </div>
              </div>

              <div className="self-center lg:col-span-3">

                <Swiper
                  // slidesPerView={3}
                  breakpoints={{
                    320: {
                      width: 320,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    }
                  }}
                  spaceBetween={10}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div class="flex">
                      <img src="../Coacher.jpg" className="rounded-xl lg:h-44 h-56" />
                      <div class="p-2 z-ind bg-gray-200 grid rounded-xl ">
                      <p class="text-center self-center lg:px-4 font-bold">Sports Accadmy & Coaching Center</p>
                      </div>

                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="flex">
                      <img src="../camping.jpg" className="rounded-xl lg:h-44 h-56" />
                      <div class="p-2 z-ind bg-gray-200 grid rounded-xl ">
                      <p class="text-center self-center lg:px-4 font-bold">Camping & Recreation</p>
</div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="flex">
                      <img src="../equipments.jpg" className="rounded-xl lg:h-44 h-56" />
                      <div class="p-2 z-ind bg-gray-200 grid rounded-xl ">
                      <p class="text-center self-center lg:px-4 font-bold">Supplying Sports Goods</p>
</div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../Organizing.jpg" className="rounded-xl lg:h-44 h-56" />
                      <div class="p-2 bg-gray-200 relative z-ind-1 rounded-xl">
                      <p class="text-center self-center lg:px-4 font-bold">Organizing Sports Events & Games</p>
</div>
                    </div>
                  </SwiperSlide>
                  
                </Swiper>
              </div>

            </div>
          </div>
        </section>

        <section>
          <div className="product-showcase-strip py-20 bg-gray-200">
            <div className="product-list grid grid-cols-1 lg:grid-cols-3 container mx-auto box-rev">

              {/* <div className="products col-span-1 p-2 self-center">
                        <img src="./cricketplayer.png" />
                        <h3 className="products-name font-bold text-xl">Cricket</h3>
                      </div>
              
                      <div className="products col-span-1 p-2 self-center">
                        <img src="./football.png" />
                        <h3 className="products-name font-bold text-xl">Football</h3>
                      </div>
            
                      <div className="products col-span-1 p-2 self-center">
                        <img src="./running.png" />
                        <h3 className="products-name font-bold text-xl">Long Jump</h3>
                      </div> */}
              <div className="self-auto lg:self-center lg:col-span-2 ">

                <Swiper
                  breakpoints={{
                    320: {
                      width: 320,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    }
                  }}
                  spaceBetween={20}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src="../Eque1.png" className="rounded-xl" />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../Eque2.png" className="rounded-xl" />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../Eque3.png" className="rounded-xl" />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../Eque4.png" className="rounded-xl" />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../Eque5.png" className="rounded-xl" />

                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="showcase-layer col-span-1 pb-12 lg:p-2 ml-0">
                <div className="self-center">
                  <h3 className="team-title text-3xl text-center bg-yellow-400 font-extrabold font-color uppercase">Our Equipments</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div> 
          <Footer />
        </div>
      </div>


    );

  }
} 